import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';


function RequestAdvReportsService(token) {
    
    return axios.post(`${CURRENT_API_URL}/amazonreports/RequestAdvReports`, '', {
        
        headers: {
            'Authorization': token,
        }
        
    }).then( notify.NotifySuccess("Running Request  Avertising Reports."))        
        .catch(() => {
                notify.NotifyError("An error occurred while running request advertising reports job.");
                return false;
            });
}


export {
    RequestAdvReportsService,
}